<template>
  <div>
      <component :is="componentName" />
  </div>
</template>

<script>
import ActiveSubscribe from './activeSubscribe.vue' 
import StopSubscribe from './stopSubscribe.vue' 
import ExpiredSubscribe from './expiredSubscribe.vue' 
import AllSubscribe from './all.vue' 
import RemovedSubscribe from './removedSubscribe.vue' 
export default {
  components: {
    ActiveSubscribe,
    ExpiredSubscribe,
    StopSubscribe,
    AllSubscribe,
    RemovedSubscribe
  },
computed: {
    componentName() {
      const type = this.$route.query.type;
      if (type === 'stop') {
        return 'StopSubscribe';
      } else if (type === 'active') {
        return 'ActiveSubscribe';
      }else if (type === 'expired') {
        return 'ExpiredSubscribe';
      }else if (type === 'removed') {
        return 'RemovedSubscribe';
      } 
      else {
        return 'AllSubscribe';
      }
    },
  },
}
</script>

<style>

</style>