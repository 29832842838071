<template>
  <div>
    <div class="breadCurmb mt-3">
      <label>
        <router-link to="/">{{ $t("Home") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $t("Sales") }}</label>
      <span>/</span>
      <label active>{{ $t("Subscribes") }}</label>
    </div>

    <!-- Start Dialog Stop -->
    <v-dialog v-model="dialogStop" width="50rem">
      <v-card>
        <v-toolbar color="third" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("StopSubscribe") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row class="mt-5">
              <v-col class="py-0" :cols="4">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.start_date`),
                  }"
                >
                  <label>{{ $t("StartDate") }}</label>

                  <el-date-picker
                    style="width: 100%"
                    v-model="stopForm.start_date"
                    type="date"
                    v-validate="'required'"
                    data-vv-scope="addEditValidation"
                    :data-vv-as="$t('StartDate')"
                    class="d-block my-2"
                    name="start_date"
                    hide-details
                    dense
                    outlined
                  >
                  </el-date-picker>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.start_date`)"
                >
                  {{ errors.first(`addEditValidation.start_date`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="4">
                <label>{{ $t("EndDate") }}</label>
                <el-date-picker
                  style="width: 100%"
                  v-model="stopForm.end_date"
                  type="date"
                  class="d-block my-2"
                  name="end_date"
                  hide-details
                  dense
                  outlined
                >
                </el-date-picker>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="dialogStop = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="dialogStopConfirm"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Dialog Delete -->

    <!-- Start Dialog Start -->
    <v-dialog v-model="dialogStart" width="50rem">
      <v-card>
        <v-toolbar color="third" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("StartSubscribe") }}
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-container fluid>
            <v-row class="mt-5">
              <v-col class="py-0" :cols="4">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.date`),
                  }"
                >
                  <label>{{ $t("Date") }}</label>
                  <el-date-picker
                    style="width: 100%"
                    v-model="startForm.date"
                    type="date"
                    v-validate="'required'"
                    data-vv-scope="addEditValidation"
                    :data-vv-as="$t('Date')"
                    class="d-block my-2"
                    name="date"
                    hide-details
                    dense
                    outlined
                  >
                  </el-date-picker>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.date`)"
                >
                  {{ errors.first(`addEditValidation.date`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="4">
                <label>{{ $t("StopStartDate") }}</label>
                <el-date-picker
                  v-if="
                    startItem &&
                    startItem.stop_subscription &&
                    startItem.stop_subscription.start_date &&
                    startItem.stop_subscription.start_date !== null
                  "
                  style="width: 100%"
                  :value="startItem.stop_subscription.start_date"
                  type="date"
                  class="d-block my-2"
                  name="date"
                  hide-details
                  dense
                  readonly
                  outlined
                >
                </el-date-picker>
                <v-else
                  v-if="
                    startItem &&
                    startItem.stop_subscription &&
                    startItem.stop_subscription.start_date == null
                  "
                >
                  <h4 class="mt-5" style="color: red">
                    {{ $t("NoStopStartDate") }}
                  </h4>
                </v-else>
              </v-col>

              <v-col class="py-0" :cols="4">
                <label>{{ $t("StopEndDate") }}</label>
                <el-date-picker
                  v-if="
                    startItem &&
                    startItem.stop_subscription &&
                    startItem.stop_subscription.end_date &&
                    startItem.stop_subscription.end_date !== null
                  "
                  style="width: 100%"
                  :value="startItem.stop_subscription.end_date"
                  type="date"
                  class="d-block my-2"
                  name="date"
                  hide-details
                  dense
                  outlined
                  readonly
                >
                </el-date-picker>
                <v-else
                  v-if="
                    startItem &&
                    startItem.stop_subscription &&
                    startItem.stop_subscription.end_date == null
                  "
                >
                  <h4 class="mt-5" style="color: red">
                    {{ $t("NoStopEndDate") }}
                  </h4>
                </v-else>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="dialogStart = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            v-if="
              user &&
              user.permissions &&
              user.permissions.includes('start_subscribe')
            "
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="dialogStartConfirm"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Dialog Delete -->

    <!-- Start Dialog -->
    <v-dialog v-model="dialogComplaintAdd" width="50rem">
      <v-card>
        <v-toolbar style="background: red" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("AddComplaint") }}
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-container fluid>
            <v-row class="mt-5">
              <v-col class="py-0" :cols="4">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.date`),
                  }"
                >
                  <label>{{ $t("Date") }}</label>
                  <el-date-picker
                    style="width: 100%"
                    v-model="complaintForm.date"
                    type="date"
                    v-validate="'required'"
                    data-vv-scope="addEditValidation"
                    :data-vv-as="$t('Date')"
                    class="d-block my-2"
                    name="date"
                    hide-details
                    dense
                    outlined
                  >
                  </el-date-picker>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.date`)"
                >
                  {{ errors.first(`addEditValidation.date`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="4">
                <div
                  :class="{
                    'has-error': errors.has(
                      `addEditValidation.complaint_division`
                    ),
                  }"
                >
                  <label>{{ $t("ComplaintDivision") }}</label>
                  <v-autocomplete
                    class="d-block my-2"
                    name="complaint_division_id"
                    data-vv-scope="addEditValidation"
                    v-validate="'required'"
                    :data-vv-as="$t('ComplaintDivision')"
                    hide-details
                    dense
                    outlined
                    item-value="id"
                    item-text="name_translate"
                    :items="complaintDivisionList"
                    v-model="complaintForm.complaint_division_id"
                    @change="getComplaintTypesData"
                  ></v-autocomplete>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.complaint_division_id`)"
                >
                  {{ errors.first(`addEditValidation.complaint_division_id`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="4">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.complaint_type`),
                  }"
                >
                  <label>{{ $t("ComplaintType") }}</label>
                  <v-autocomplete
                    class="d-block my-2"
                    name="complaint_type_id"
                    data-vv-scope="addEditValidation"
                    v-validate="'required'"
                    :data-vv-as="$t('ComplaintType')"
                    hide-details
                    dense
                    outlined
                    item-value="id"
                    item-text="name_translate"
                    clearable
                    :items="complaintTypeList"
                    v-model="complaintForm.complaint_type_id"
                  ></v-autocomplete>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.complaint_type_id`)"
                >
                  {{ errors.first(`addEditValidation.complaint_type_id`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="12">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.note`),
                  }"
                >
                  <label>{{ $t("ComplaintText") }}</label>
                  <v-textarea
                    rows="3"
                    class="d-block my-2"
                    name="note"
                    data-vv-scope="addEditValidation"
                    v-validate="'required'"
                    :data-vv-as="$t('ComplaintText')"
                    hide-details
                    dense
                    outlined
                    v-model="complaintForm.note"
                  ></v-textarea>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.note`)"
                >
                  {{ errors.first(`addEditValidation.note`) }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="dialogComplaintAdd = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="dialogComplaintSave"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Dialog -->

    <!-- Start Dialog -->
    <v-dialog v-model="dialogCompensationAdd" width="50rem">
      <v-card>
        <v-toolbar style="background: green" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("AddCompensation") }}
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-container fluid>
            <v-row class="mt-5">
              <v-col class="py-0" :cols="4">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.date`),
                  }"
                >
                  <label>{{ $t("Date") }}</label>
                  <el-date-picker
                    style="width: 100%"
                    v-model="compensationForm.date"
                    type="date"
                    v-validate="'required'"
                    data-vv-scope="addEditValidation"
                    :data-vv-as="$t('Date')"
                    class="d-block my-2"
                    name="date"
                    hide-details
                    dense
                    outlined
                  >
                  </el-date-picker>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.date`)"
                >
                  {{ errors.first(`addEditValidation.date`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="4">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.number_of_days`),
                  }"
                >
                  <label>{{ $t("NumberOfDays") }}</label>
                  <v-text-field
                    rows="3"
                    class="d-block my-2"
                    name="number_of_days"
                    data-vv-scope="addEditValidation"
                    v-validate="'required'"
                    :data-vv-as="$t('NumberOfDays')"
                    hide-details
                    dense
                    outlined
                    clearable
                    v-model="compensationForm.number_of_days"
                  ></v-text-field>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.number_of_days`)"
                >
                  {{ errors.first(`addEditValidation.number_of_days`) }}
                </div>
              </v-col>
              <v-col class="py-0" :cols="4">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.reason`),
                  }"
                >
                  <label>{{ $t("Reason") }}</label>
                  <v-textarea
                    rows="3"
                    class="d-block my-2"
                    name="reason"
                    data-vv-scope="addEditValidation"
                    v-validate="'required'"
                    :data-vv-as="$t('Reason')"
                    hide-details
                    dense
                    outlined
                    v-model="compensationForm.reason"
                  ></v-textarea>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.reason`)"
                >
                  {{ errors.first(`addEditValidation.reason`) }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="dialogCompensationAdd = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="dialogCompensationSave"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Dialog -->


     <!-- Start Dialog -->
    <v-dialog v-model="dialogStopAllSubscribtion" width="50rem">
      <v-card>
        <v-toolbar style="background: red" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("StopAllSubscribtion") }}
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-container fluid>
            <v-row class="mt-5">
              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.from_data`),
                  }"
                >
                  <label>{{ $t("FromDate") }}</label>
                  <el-date-picker
                    style="width: 100%"
                    v-model="stopAllSubscribtionForm.start_date"
                    type="date"
                    v-validate="'required'"
                    data-vv-scope="addEditValidation"
                    :data-vv-as="$t('FromDate')"
                    class="d-block my-2"
                    name="from_data"
                    hide-details
                    dense
                    outlined
                  >
                  </el-date-picker>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.from_data`)"
                >
                  {{ errors.first(`addEditValidation.from_data`) }}
                </div>
              </v-col>

                <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.to_data`),
                  }"
                >
                  <label>{{ $t("ToDate") }}</label>
                  <el-date-picker
                    style="width: 100%"
                    v-model="stopAllSubscribtionForm.end_date"
                    type="date"
                    v-validate="'required'"
                    data-vv-scope="addEditValidation"
                    :data-vv-as="$t('ToDate')"
                    class="d-block my-2"
                    name="to_data"
                    hide-details
                    dense
                    outlined
                  >
                  </el-date-picker>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.to_data`)"
                >
                  {{ errors.first(`addEditValidation.to_data`) }}
                </div>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="dialogStopAllSubscribtion = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="dialogStopAllSubscribtionSave"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Dialog -->

     <!-- Start Dialog -->
    <v-dialog v-model="dialogActivateAllSubscribtion" width="50rem">
      <v-card>
        <v-toolbar style="background: green" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("ActivateAllSubscribtion") }}
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-container fluid>
            <v-row class="mt-5">
              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.date`),
                  }"
                >
                  <label>{{ $t("Date") }}</label>
                  <el-date-picker
                    style="width: 100%"
                    v-model="activateAllSubscribtionForm.date"
                    type="date"
                    v-validate="'required'"
                    data-vv-scope="addEditValidation"
                    :data-vv-as="$t('Date')"
                    class="d-block my-2"
                    name="date"
                    hide-details
                    dense
                    outlined
                  >
                  </el-date-picker>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.date`)"
                >
                  {{ errors.first(`addEditValidation.date`) }}
                </div>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="dialogActivateAllSubscribtion = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="dialogActivateAllSubscribtionSave"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Dialog -->


    <!-- Start Dialog Delete -->
    <v-dialog v-model="dialogDelete" :width="config.deleteModalWidth">
      <v-card>
        <v-toolbar color="third" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("Confirm") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <h3 class="clr-primary">
            {{ $t("AreYouSureYouWantToDelete") }}
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="dialogDelete = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="deleteItemConfirm"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Dialog Delete -->

    <!-- Start  Status Delete Dialog  -->
    <v-dialog v-model="dialogStatusDelete" :width="config.deleteModalWidth">
      <v-card>
        <v-toolbar color="danger" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("Confirm") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <h3 class="clr-primary">
            {{ $t("AreYouSureYouWantToDeleteStatus") }}
          </h3>
        </v-card-text>
        <v-container>
          <v-row>
            <v-col class="py-0" cols="12">
              <div
                :class="{
                  'has-error': errors.has(
                    `addEditValidation.cancellation_reason`
                  ),
                }"
              >
                <v-text-field
                  placeholder="سبب الإلغاء"
                  class="d-block my-2"
                  name="cancellation_reason"
                  data-vv-scope="addEditValidation"
                  v-validate="'required'"
                  :data-vv-as="'سبب الإلغاء'"
                  hide-details
                  dense
                  outlined
                  v-model="cancellation_reason"
                >
                </v-text-field>
              </div>

              <div
                class="help-block"
                v-if="errors.has(`addEditValidation.cancellation_reason`)"
              >
                {{ errors.first(`addEditValidation.cancellation_reason`) }}
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="dialogStatusDelete = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="deleteStatusItemConfirm"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End  Status Delete Dialog  -->

    <!-- Start  Status reset Dialog  -->
    <v-dialog v-model="dialogStatusReset" :width="config.deleteModalWidth">
      <v-card>
        <v-toolbar color="success" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("Confirm") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <h3 class="clr-primary">
            {{ $t("AreYouSureYouWantToResetStatus") }}
          </h3>

           <v-container>
          <v-row>
            <v-col class="py-0" cols="12">
              <div
                :class="{
                  'has-error': errors.has(
                    `addEditValidation.reset_date`
                  ),
                }"
              >

               <el-date-picker
                    style="width: 100%"
                    v-model="reset_date"
                    type="date"
                    v-validate="'required'"
                    data-vv-scope="addEditValidation"
                    data-vv-as="تاريخ إلغاء الاشتراك"
                    class="d-block my-2"
                    name="reset_date"
                    hide-details
                    dense
                    outlined
                  >
                  </el-date-picker>
              </div>

              <div
                class="help-block"
                v-if="errors.has(`addEditValidation.reset_date`)"
              >
                {{ errors.first(`addEditValidation.reset_date`) }}
              </div>
            </v-col>
          </v-row>
        </v-container>
        </v-card-text>

        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="dialogStatusReset = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="resetStatusItemConfirm"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End  Status reset Dialog  -->

    <v-toolbar flat>
      <v-toolbar-title>{{ $t(config.tableName) }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog :width="config.modalFormWidth" @click:outside="closeModal">
        <template v-slot:activator="{ on, attrs }">
          <div>
<!--            <v-btn-->
<!--              v-if="-->
<!--                user &&-->
<!--                user.permissions &&-->
<!--                user.permissions.includes('store_subscribe')-->
<!--              "-->
<!--              color="info"-->
<!--              @click="clearData"-->
<!--              class="mb-2"-->
<!--              v-bind="attrs"-->
<!--              v-on="on"-->
<!--              small-->
<!--            >-->
<!--              <span class="addNewLabel">{{ $t("AddNew") }}</span>-->

<!--              <v-icon class="my-0 mr-2 ml-2" dense>mdi-file</v-icon>-->
<!--            </v-btn>-->

            <v-btn
              v-if="
                user &&
                user.permissions &&
                user.permissions.includes('store_subscribe')
              "
              color="info"
              @click="clearData"
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              small
            >
              <span class="addNewLabel">{{ $t("AddNew") }}</span>

              <v-icon class="my-0 mr-2 ml-2" dense>mdi-file</v-icon>
            </v-btn>

            <!-- <v-btn
              color="danger"
              style="color:#fff;"
              @click="addStopAllSubscribtions"
              class="mb-2 mx-2"
              small
            >
              <span class="addNewLabel">{{ $t("StopAllSubscribtion") }}</span>

              <v-icon class="my-0  mr-2 ml-2" dense>mdi-minus</v-icon>
            </v-btn> -->

            <!-- <v-btn
              color="success"
              style="color:#fff;"
              @click="addActivateAllSubscribtions"
              class="mb-2 mx-2"
              small
            >
              <span class="addNewLabel">{{ $t("ActivateAllSubscribtion") }}</span>

              <v-icon class="my-0  mr-2 ml-2" dense>mdi-plus</v-icon>
            </v-btn> -->


            <download-excel
              class="mb-2 mx-5 cursorPointer v-btn theme--dark v-size--small success v-btn v-btn--is-elevated v-btn--has-bg"
              :data="table.items"
              :fields="table.itemExport"
              type="xls"
              :header="$t(config.tableName)"
              :worksheet="$t(config.tableName)"
              :name="$t(config.tableName)"
            >
              <span class="excelLabel">{{ $t("ExportExcel") }}</span>
              <v-icon
                class="my-0 mr-2 ml-2"
                style="color: #fff !important"
                dense
                >mdi-export</v-icon
              >
            </download-excel>

            <v-btn
              color="danger"
              @click="loadPDF"
              dark
              small
              class="mb-2 mr-2 ml-2"
            >
              <span class="pdfLabel"> {{ $t("LoadPDF") }} </span>
              <v-icon class="my-0 mr-2 ml-2" dense>mdi-eye</v-icon>
            </v-btn>
          </div>
        </template>
      </v-dialog>
    </v-toolbar>

    <!-- Start Filters -->
    <div>
      <v-row>
        <v-col :md="3">
          <div>
            <label>{{ $t("Name") }}</label>
            <v-text-field
              class="d-block my-2"
              type="text"
              name="customer_name"
              hide-details
              dense
              outlined
              v-model="filters.customer_name"
              @input="updateParam($event, 'customer_name')"
            ></v-text-field>
          </div>
        </v-col>
        <v-col :md="2">
          <div>
            <label>{{ $t("Mobile") }}</label>
            <v-text-field
              class="d-block my-2"
              type="text"
              name="customer_name"
              hide-details
              dense
              outlined
              @input="updateParam($event, 'customer_mobile')"
              v-model="filters.customer_mobile"
            ></v-text-field>
          </div>
        </v-col>

        <v-col :md="2">
          <div>
            <label>{{ $t("City") }}</label>
            <v-autocomplete
              :clearable="true"
              class="d-block my-2"
              name="status"
              hide-details
              dense
              outlined
              :multiple="false"
              item-text="name"
              item-value="id"
              v-model="filters.city_id"
              @input="
                updateParam($event, 'city_id');
                updateParam('', 'branch_id');
              "
              :items="cityList"
              @change="getNeighborhoodList(filters.city_id)"
            ></v-autocomplete>
          </div>
        </v-col>

        <v-col :md="2">
          <div>
            <label>{{ $t("Neighborhood") }}</label>
            <v-autocomplete
              :clearable="true"
              class="d-block my-2"
              name="status"
              hide-details
              dense
              outlined
              :multiple="false"
              item-text="name"
              item-value="id"
              @input="updateParam($event, 'branch_id')"
              v-model="filters.branch_id"
              :items="branchList"
            ></v-autocomplete>
          </div>
        </v-col>

        <v-col :md="1" class="mt-6">
          <v-btn
            color="third"
            hide-details
            @click="getDataFromApi"
            style="display: block; margin-top: 10px"
            class="w-full h-btn white-clr"
            small
          >
            {{ $t("Filter") }}
          </v-btn>
        </v-col>
        <v-col :md="1" class="mt-6">
          <v-btn
            color="danger"
            hide-details
            @click="clearFilters"
            style="display: block; margin-top: 10px"
            class="w-full h-btn white-clr"
            small
          >
            {{ $t("Clear") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <!-- End Filters -->

    <!-- Start DataTable -->
    <v-data-table
      :headers="table.headers"
      :items="table.items"
      :loading="table.loading"
      :items-per-page="table.itemsPerPage"
      :page.sync="table.page"
      hide-default-footer
      class="elevation-1"
      :loading-text="$t('LoadData')"
    >
      <template v-slot:no-data>
        <v-alert class="mt-5 white-clr" color="third">
          {{ $t("NoData") }}
        </v-alert>
      </template>

      <template v-slot:item.subscribeIsEnded="{ item }">
        <p
          :style="`color:${item.subscribe_is_ended.color}`"
          v-if="item.subscribe_is_ended.is_ended_text"
          class="my-0"
        >
          {{ item.subscribe_is_ended.is_ended_text }}
        </p>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          class="my-0 mr-2 ml-2"
          dense
          @click="goDetails($event, item.customer_id)"
          style="color: purple !important; cursor: pointer; font-size: 22px"
          >mdi-eye</v-icon
        >
        <svg
          v-if=" user &&
            user.permissions &&
            user.permissions.includes('complaints_subscribe')"
          class="mr-2 ml-2"
          style="position: relative; top: 5px; cursor: pointer"
          @click="addComplaint(item)"
          height="22px"
          viewBox="0 0 22 22"
          width="18px"
          fill="red"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"
          />
        </svg>

        <svg
         v-if=" user &&
            user.permissions &&
            user.permissions.includes('compensation_subscribe')"
          class="mr-2 ml-2"
          style="position: relative; top: 5px; cursor: pointer"
          @click="addCompensation(item)"
          height="22px"
          viewBox="0 0 22 22"
          width="18px"
          fill="green"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"
          />
        </svg>

        <!-- <svg
         class="mr-2 ml-2"
          style="position: relative; top: 5px; cursor: pointer"
          @click="addCompensation(item)"
          fill="green"
          width="18px"
          height="22px"
          viewBox="0 0 1024 1024"
        >
          <path
            d="M618.667 264.533L699.734 102.4c8.533-17.067 8.533-38.4 0-55.467s-29.867-25.6-46.933-25.6H366.934c-21.333 0-38.4 8.533-46.933 25.6s-8.533 38.4 0 55.467l81.067 162.133C187.735 294.4 21.335 482.133 21.335 704v46.933c0 136.533 110.933 251.733 251.733 251.733h477.867c136.533 0 251.733-110.933 251.733-251.733V704c-4.267-226.133-170.667-409.6-384-439.467zM358.4 81.067c-4.267-4.267 0-12.8 0-12.8S362.667 64 371.2 64h281.6c8.533 0 8.533 4.267 12.8 4.267 0 0 4.267 8.533 0 12.8L576 256H448L358.4 81.067zm597.333 669.866c0 115.2-93.867 209.067-209.067 209.067h-473.6c-115.2 0-209.067-93.867-209.067-209.067V704c0-221.867 179.2-401.067 401.067-401.067h89.6c221.867 0 401.067 179.2 401.067 401.067v46.933z"
          />
        </svg> -->

        <v-icon
          v-if="
            item.subscribe_is_ended.is_ended != 2 &&
            item.subscribe_is_ended.is_ended != 3 &&
            user &&
            user.permissions &&
            user.permissions.includes('cancel_subscribe')
          "
          dense
          @click="
            formData = Object.assign({}, item);
            dialogStatusDelete = true;
            cancellation_reason = null;
            $validator.reset();
          "
          class="my-2 mr-2 ml-2"
          style="color: red !important; cursor: pointer; font-size: 24px"
          >mdi-close</v-icon
        >

        <v-icon
          v-if="
            item.subscribe_is_ended.is_ended == 3
          "
          dense
          @click="
            formData = Object.assign({}, item);
            dialogStatusReset = true;
            reset_date = null;
            $validator.reset();
          "
          class="my-2 mr-2 ml-2"
          style="color: blue !important; cursor: pointer; font-size: 24px"
          >mdi-refresh</v-icon
        >

        <v-icon
          v-if="
            item.subscribe_is_ended.is_ended == 1 &&
            user &&
            user.permissions &&
            user.permissions.includes('stop_subscribe')
          "
          class="my-0 mr-2 ml-2"
          dense
          @click="stop(item)"
          style="color: red !important; cursor: pointer; font-size: 18px"
          >mdi-block-helper</v-icon
        >

        <v-icon
          v-else-if="
            (item.subscribe_is_ended.is_ended == 0 || item.subscribe_is_ended.is_ended == 5)
            && user &&
            user.permissions &&
            user.permissions.includes('start_subscribe')
          "
          class="my-0 mr-2 ml-2"
          dense
          @click="start(item)"
          style="color: green !important; cursor: pointer; font-size: 24px"
          >mdi-check-circle</v-icon
        >
        
        
        <v-icon
          v-else-if="
           (item.subscribe_is_ended.is_ended == 0 || item.subscribe_is_ended.is_ended == 5)
            && item.subscribe_is_ended.is_ended == 0 &&
            user &&
            user.permissions &&
            user.permissions.includes('button_edit_subscribe')
          "
          class="my-0 mr-2 ml-2"
          dense
          @click="start(item)"
          style="color: green !important; cursor: pointer; font-size: 24px"
          >mdi-check-circle</v-icon
        >

        <v-icon
          v-if="
            user &&
            user.permissions &&
            user.permissions.includes('store_subscribe')
          "
          class="my-0 mr-2 ml-2"
          dense
          @click="
            $router.push({ name: 'subscribeRenewal', params: { id: item.id,customer_id : item.customer_id,type_sub:'renewal' } })
          "
          color="success"
          >mdi-plus</v-icon
        >

<!--        <v-icon-->
<!--          class="my-0 mr-2 ml-2"-->
<!--          dense-->
<!--          @click="-->
<!--            $router.push({ name: 'subscribeaddEdit', params: { id: item.id } })-->
<!--          "-->
<!--          color="success"-->
<!--          >mdi-pencil</v-icon-->
<!--        >-->
<!--        <v-icon-->
<!--          class="my-0 mr-2 ml-2"-->
<!--          dense-->
<!--          @click="-->
<!--            $router.push({ name: 'subscribeEdit', params: { id: item.id } })-->
<!--          "-->
<!--          color="success"-->
<!--          >mdi-pencil</v-icon-->
<!--        >-->
        <v-icon
          class="my-0 mr-2 ml-2"
          dense
          @click="
            $router.push({ name: 'subscribeEditAll', params: { id: item.id,customer_id : item.customer_id } })
          "
          color="success"
          >mdi-pencil</v-icon
        >
<!--        <v-icon class="my-0 mr-2 ml-2"-->
<!--          dense-->
<!--          @click="-->
<!--            $router.push({ name: 'customer-edit', params: { id: item.customer_id } })-->
<!--          "-->
<!--          color="success"-->
<!--          >mdi-account-edit</v-icon-->
<!--        >-->

<!--        <v-icon-->
<!--            class="my-0 mr-2 ml-2"-->
<!--            dense-->
<!--            @click="$router.push({ name: 'subscribeDeliverDetails', params: { id: item.id } })"-->
<!--            color="success"-->
<!--        >-->
<!--          mdi-map-marker-->
<!--        </v-icon>-->


        <!-- <img
          @click="openGallery(item.customer_attachments, item.home_number)"
          style="border-radius: 50%; margin: 0 5px; cursor: pointer"
          class="mr-2 mt-2"
          width="50"
          height="50"
          v-if="item.customer_attachments"
          :src="
            item.customer_attachments.length > 0
              ? ourconfig.hostUrl+ item.customer_attachments[item.customer_attachments.length - 1]
              : '/assets/no-img.png'
          "
          alt=""
        /> -->


        <v-icon
          v-if="
            user &&
            user.permissions &&
            user.permissions.includes('delete_subscribe')
          "
          class="my-0 mr-2 ml-2"
          dense
          @click="
            formData = Object.assign({}, item);
            dialogDelete = true;
          "
          color="danger"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <!-- End DataTable -->

    <!-- Start Pagination -->
    <div class="text-center pt-2">
      <v-pagination
        v-if="table.length"
        total-visible="5"
        v-model="table.page"
        :length="table.length"
        @input="changePage(table.page)"
      ></v-pagination>
    </div>

    <!-- End Pagination -->


     <v-dialog v-model="galaryModal" width="60rem">
      <v-card>
        <v-toolbar
          style="background: brown !important; color: #000 !important"
          dense
          flat
        >
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            معرض الصور
          </v-toolbar-title>
          <span
            @click="
              galaryModal = false;
              galaryIndex = 0;
            "
            style="
              color: #fff;
              cursor: pointer;
              position: absolute;
              top: 5px;
              left: 10px;
              font-size: 22px;
            "
            >x</span
          >
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <img
            style="width: 90%; height: 300px; display: block; margin: 0 auto"
            :src="ourconfig.hostUrl+galaryImages[galaryIndex]"
            alt=""
          />

          <h2 class="mx-5 my-5 text-center">رقم المنزل : <span>{{homeNum}}</span></h2>
        </v-card-text>
        <v-card-actions>
          <div style="display: block; margin: 0 auto">
            <v-btn
              v-if="galaryIndex < galaryImages.length - 1"
              small
              style="background: #000 !important"
              class="body-2 font-weight-bold mx-2"
              @click.native="galaryIndex = galaryIndex + 1"
            >
              <v-icon
                class="my-0 mr-2 mx-2"
                style="font-size: 28px; color: #fff"
                dense
                >mdi-arrow-right</v-icon
              >
            </v-btn>
            <v-btn
              v-if="galaryIndex > 0"
              small
              style="background: #000 !important"
              class="body-2 font-weight-bold"
              @click.native="galaryIndex = galaryIndex - 1"
            >
              <v-icon
                class="my-0 mr-2 ml-2"
                style="font-size: 28px; color: #fff"
                dense
                >mdi-arrow-left</v-icon
              >
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import moment from "moment";
import config from '@/config';

export default {
  name: "packages",
  data() {
    return {
      ourconfig:config,
      reset_date: null,
      cancellation_reason: null,
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : [],
      cityList: [],
      branchList: [],
      filters: {
        customer_name: this.$route.query.customer_name || "",
        customer_mobile: this.$route.query.customer_mobile || "",
        city_id: this.$route.query.city_id || "",
        branch_id: this.$route.query.branch_id || "",
        // status: null,
        // group_id: null,
      },
      filtersItems: [
        { text: this.$t("All"), value: null },
        { text: this.$t("Active"), value: 1 },
        { text: this.$t("InActive"), value: 0 },
      ],
      config: {
        tableName: "SubscribesTable",
        crudHeader: "Subscribes",
        crudApi: "subscribe",
        filterWidth: "20rem",
        deleteModalWidth: "30rem",
        modalFormWidth: "50rem",
      },
      table: {
        page: 1,
        length: null,
        itemsPerPage: 2000,
        loading: false,
        items: [],
        itemExport: {
          "#": "id",
          العميل: {
            field: "customer_name",
            callback: (item) => {
              if (item) return item;
            },
          },
          الموبايل: {
            field: "customer_mobile",
            callback: (item) => {
              if (item) return item;
            },
          },
          المدينة: {
            field: "city_name",
            callback: (item) => {
              if (item) return item;
            },
          },
          الحي: {
            field: "branch_name",
            callback: (item) => {
              if (item) return item;
            },
          },
          الباقة: {
            field: "package_name",
            callback: (item) => {
              if (item) return item;
            },
          },
          "من تاريخ": {
            field: "from_date",
            callback: (item) => {
              if (item) return item;
            },
          },
          "إلى تاريخ": {
            field: "to_date",
            callback: (item) => {
              if (item) return item;
            },
          },
        },
        headers: [
          { text: "#", value: "id", width: "5rem" },
          {
            text: "من ناحية الموبيل",
            value: "from_mobile",
            width: "8rem",
          },
          {
            text: this.$t("CustomerName"),
            value: "customer_name",
            width: "8rem",
          },
          {
            text: this.$t("Mobile"),
            value: "customer_mobile",
            width: "7rem",
          },
          {
            text: this.$t("City"),
            value: "city_name",
            width: "7rem",
          },
          {
            text: this.$t("Neighborhood"),
            value: "branch_name",
            width: "7rem",
          },
          {
            text: this.$t("PackageName"),
            value: "package_name",
            width: "8rem",
          },
          {
            text: this.$t("StartDate"),
            value: "start_date",
            width: "7rem",
          },
          {
            text: this.$t("EndDate"),
            value: "end_date",
            width: "7rem",
          },
          {
            text: this.$t("Status"),
            value: "subscribeIsEnded",
            width: "7rem",
          },
        ],
      },
      formData: {
        id: 0,
      },
      stopForm: {
        start_date: null,
        end_date: null,
      },
      startForm: {
        date: null,
      },
      drawer: false,
      dialog: false,
      dialogDelete: false,
      dialogStatusDelete: false,
      dialogStatusReset: false,
      stopItem: null,
      dialogStop: false,
      startItem: null,
      complaintForm: {
        complaint_division_id: null,
        complaint_type_id: null,
        date: null,
        note: null,
      },
      compensationForm: {
        date: null,
        number_of_days: null,
        reason: null,
      },
      stopAllSubscribtionForm: {
        start_date: null,
        to_date: null,
      },
      activateAllSubscribtionForm: {
        date: null,
      },
      complaintItem: null,
      compensationItem: null,
      dialogStart: false,
      dialogComplaintAdd: false,
      dialogCompensationAdd: false,
      dialogStopAllSubscribtion: false,
      dialogActivateAllSubscribtion: false,
      complaintDivisionList: [],
      complaintTypeList: [],

      galaryImages: [],
      galaryIndex: 0,
      galaryModal: false,
      homeNum: '',
    };
  },
  methods: {
    // openGallery(images, homeNum) {
    //   if (images.length > 0) {
    //     this.galaryImages = images;
    //     this.galaryIndex = 0;
    //     this.galaryModal = true;
    //     this.homeNum= homeNum;
    //   }
    // },
    getComplaintTypesData() {
      this.complaintTypeList = [];
      this.$store
        .dispatch(
          `complaintType/listComplaintDivisionById`,
          this.complaintForm.complaint_division_id
        )
        .then((res) => {
          this.complaintTypeList = res.data;
        });
    },
    dialogComplaintSave() {
      let params = {
        subscription_id: this.complaintItem.id,
        complaint_type_id: this.complaintForm.complaint_type_id,
        date: moment(this.complaintForm.date).format("YYYY-MM-DD"),
        notes: this.complaintForm.note,
      };

      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");

        this.$store.dispatch(`subscribe/addComplaint`, params).then((res) => {
          this.notifySuccess("AddComplaint", res);
          this.dialogComplaintAdd = false;
        });
      });
    },
    dialogCompensationSave() {
      let params = {
        subscription_id: this.compensationItem.id,
        number_of_days: this.compensationForm.number_of_days,
        date: moment(this.compensationForm.date).format("YYYY-MM-DD"),
        reason: this.compensationForm.reason,
      };

      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");

        this.$store
          .dispatch(`subscribe/addCompensation`, params)
          .then((res) => {
            this.notifySuccess("AddCompensation", res);
            this.dialogCompensationAdd = false;
          });
      });
    },
    dialogStopAllSubscribtionSave() {
      let params = {
        start_date: moment(this.stopAllSubscribtionForm.start_date).format("YYYY-MM-DD"),
        end_date: moment(this.stopAllSubscribtionForm.end_date).format("YYYY-MM-DD"),
      };

      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");

        this.$store
          .dispatch(`subscribe/stopAllSubscribtion`, params)
          .then((res) => {
            this.notifySuccess("StopSubscribtions", res);
            this.dialogStopAllSubscribtion = false;
          });
      });
    },
    dialogActivateAllSubscribtionSave() {
      let params = {
        date: moment(this.activateAllSubscribtionForm.date).format("YYYY-MM-DD"),
      };

      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");

        this.$store
          .dispatch(`subscribe/activateAllSubscribtion`, params)
          .then((res) => {
            this.notifySuccess("ActivateSubscribtions", res);
            this.dialogActivateAllSubscribtion = false;
          });
      });
    },
    updateParam(value, key) {
      const query = { ...this.$route.query, [key]: value };
      const route = { ...this.$route, query };
      this.$router.replace(route);
    },
    goDetails(ev, id) {
      //  if (!ev.ctrlKey) {
      //    localStorage.setItem('customer_details_from', 'subscribes');
      //    this.$router.push({ name: "customerDetails", params: { id: id } });
      // } else {
        ev.preventDefault(); // Prevent the execution of the click event when the control key is held down
        localStorage.setItem('customer_details_from', 'subscribes');
        const route = this.$router.resolve({ name: 'customerDetails', params: { id: id } });
        window.open(route.href, '_blank');
      // }
     
    },
    stop(item) {
      this.$validator.reset();
      this.stopForm = {
        start_date: null,
        end_date: null,
      };
      this.stopItem = item;
      this.dialogStop = true;
    },
    start(item) {
      this.$validator.reset();
      this.startForm = {
        date: null,
      };
      this.startItem = item;
      this.dialogStart = true;
    },
    addComplaint(item) {
      this.$validator.reset();
      this.complaintItem = item;
      this.complaintForm = {
        complaint_division_id: null,
        complaint_type_id: null,
        date: null,
        note: null,
      };
      this.dialogComplaintAdd = true;
    },
    addCompensation(item) {
      this.$validator.reset();
      this.compensationItem = item;
      this.compensationForm = {
        date: null,
        number_of_days: null,
        reason: null,
      };
      this.dialogCompensationAdd = true;
    },
    addStopAllSubscribtions() {
      this.$validator.reset();
      this.stopAllSubscribtionForm= {
        start_date: null,
        to_date: null,
      },
      this.dialogStopAllSubscribtion = true;
    },
    addActivateAllSubscribtions() {
      this.$validator.reset();
      this.activateAllSubscribtionForm= {
        date: null,
      },
      this.dialogActivateAllSubscribtion = true;
    },
    dialogStopConfirm() {
      let sendData = {
        id: this.stopItem.id,
        formData: JSON.parse(JSON.stringify(this.stopForm)),
      };
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");

        if (sendData.formData.start_date)
          sendData.formData.start_date = moment(
            sendData.formData.start_date
          ).format("YYYY-MM-DD");

        if (sendData.formData.end_date)
          sendData.formData.end_date = moment(
            sendData.formData.end_date
          ).format("YYYY-MM-DD");

        this.$store
          .dispatch(`subscribe/stopSubscribe`, sendData)
          .then((res) => {
            this.notifySuccess("StopSubscribe", res);
            this.dialogStop = false;
            this.getDataFromApi();
          });
      });
    },
    dialogStartConfirm() {
      let sendData = {
        id: this.startItem.id,
        formData: JSON.parse(JSON.stringify(this.startForm)),
      };

      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");

        if (sendData.formData.date)
          sendData.formData.date = moment(sendData.formData.date).format(
            "YYYY-MM-DD"
          );

        this.$store
          .dispatch(`subscribe/startSubscribe`, sendData)
          .then((res) => {
            this.notifySuccess("StartSubscribe", res);
            this.dialogStart = false;
            this.getDataFromApi();
          });
      });
    },
    loadPDF() {
      localStorage.setItem("filterReport", JSON.stringify(this.filters));
      window.open(`/reports/${this.config.crudApi}`);
    },
    clearFilters() {
      for (let key in this.filters) {
        this.filters[key] = null;
      }
      this.getDataFromApi();
    },
    clearData() {
      localStorage.setItem("addSubscribeFrom", "Subscribes");
      this.formData = {
        name: {
          ar: null,
          en: null,
        },
        price: null,
        cost: null,
        number_of_meals: null,
        number_of_days: null,
        details: [],
        days: [],
      };
      this.$router.push({ name: "subscribeCreate" });
    },
    deleteItemConfirm() {
      this.deleteData(
        `${this.config.crudApi}/removeData`,
        this.formData.id
      ).then(() => {
        this.getDataFromApi();
        this.dialogDelete = false;
      });
    },
    deleteStatusItemConfirm() {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) {
          return this.notifyCustomError("Error", "ValidationsErrors");
        }
        let sendData = {
          id: this.formData.id,
          cancellation_reason: this.cancellation_reason,
        };
        this.$store
          .dispatch(`${this.config.crudApi}/cancellationReason`, sendData)
          .then(() => {
            this.getDataFromApi();
            this.dialogStatusDelete = false;
          });
      });
    },
    resetStatusItemConfirm() {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) {
          return this.notifyCustomError("Error", "ValidationsErrors");
        }
        let sendData = {
          id: this.formData.id,
          date: moment(this.reset_date).format("YYYY-MM-DD"), // handle date
        };
        this.$store
          .dispatch(`${this.config.crudApi}/returnSubscription`, sendData)
          .then(() => {
            this.getDataFromApi();
            this.dialogStatusReset = false;
          });
      });
    },
    closeModal() {
      this.formData.id = 0;
      this.dialog = false;
      this.$validator.reset();
    },
    save() {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");
        this.saveUpdateData(
          `${this.config.crudApi}/saveData`,
          `${this.config.crudApi}/updateData`,
          this.formData
        ).then(() => {
          this.getDataFromApi();
          this.closeModal();
        });
      });
    },
    getDataFromApi(options) {
      this.table.items = [];
      this.table.loading = true;
      this.getFrontEndData(
        `${this.config.crudApi}/getData`,
        options,
        this.filters
      )
        .then((res) => {
          this.table.items = res.resources.map(x=> {
            x.customer_attachments= [
              "/storage/customers/1691521457_download.jpg",
              "/storage/customers/1691521457_download (2).jpg",
            ];
            x.home_number= '1';
            return x;
          });
          this.table.length = Math.ceil(
            res.pagination.total / res.pagination.per_page
          );
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    changePage(page) {
      let options = {
        page: page,
      };
      this.getDataFromApi(options);
    },
    getNeighborhoodList(id) {
      this.filters.branch_id = null;
      this.$store.dispatch(`subscribe/branchList`, id).then((res) => {
        this.branchList = res.data;
      });
    },
  },
  created() {
    this.$store.dispatch(`subscribe/cityList`).then((res) => {
      this.cityList = res.data;
    });
    if (this.$route.query.city_id) {
      this.filters.city_id = parseInt(this.$route.query.city_id);
      this.getNeighborhoodList(this.filters.city_id);
    }
    if (this.$route.query.branch_id) {
      this.filters.branch_id = parseInt(this.$route.query.branch_id);
    }
  },
  mounted() {

    let me = this;
    window.addEventListener("keydown", function (e) {
      if (me) {
        if (e.code == "ArrowRight" && me.galaryModal == true) {
          if (me.galaryIndex < me.galaryImages.length - 1)
            me.galaryIndex = me.galaryIndex + 1;
        } else if (e.code == "ArrowLeft" && me.galaryModal == true) {
          if (me.galaryIndex > 0) me.galaryIndex = me.galaryIndex - 1;
        } else if (e.code == "Esc" && me.galaryModal == true) {
          me.galaryModal = false;
          me.galaryIndex = 0;
        }
      }
    });

    this.getDataFromApi();

    this.$store.dispatch(`complaintDivision/listAll`).then((res) => {
      this.complaintDivisionList = res.data;
    });

    if (
      (this.user &&
        this.user.permissions &&
        this.user.permissions.includes("update_subscribe")) ||
      (this.user &&
        this.user.permissions &&
        this.user.permissions.includes("delete_subscribe")) ||
      (this.user &&
        this.user.permissions &&
        this.user.permissions.includes("start_subscribe")) ||
      (this.user &&
        this.user.permissions &&
        this.user.permissions.includes("stop_subscribe")) ||
      (this.user &&
        this.user.permissions &&
        this.user.permissions.includes("button_edit_subscribe"))
    )
      this.table.headers.push({
        text: this.$t("Actions"),
        value: "actions",
        sortable: false,
        align: "center",
      });
  },
};
</script>


<style>
.v-menu__content {
  z-index: 999 !important;
}
</style>